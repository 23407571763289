.App{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generator{
  
  background-image: linear-gradient(-225deg, #20E2D7 0%, #F9FEA5 100%);
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(245, 244, 244, 0.863);
  padding: 30px;
}
.generator__header{
  text-align: center;
  color: darkgreen;
  margin-bottom: 20px;
}
.generator__password{
  background-color:#308D46;
  padding: 13px 10px;
  color: #fff;
  margin-bottom: 15px;
  height: 46px;
  display: flex;
  justify-content: space-between;
}
.copy__btn{
  color: #fff;
  background: #308D46;
  border: none;
  cursor: pointer;
}
.generator__btn{
  background: #308D46;
  border: none;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  color: #fff;
  border-radius: 30px;
  font-size: 17px;
  cursor: pointer;

}
.form-group{
  display: flex;
  justify-content: space-between;
  color: gre;
  margin-bottom: 15px;
}